export class IGroupedBlueprint {
}
export var TriggerTypeEnum;
(function (TriggerTypeEnum) {
    TriggerTypeEnum["EVENT"] = "event";
})(TriggerTypeEnum || (TriggerTypeEnum = {}));
export var TriggerContextTypeEnum;
(function (TriggerContextTypeEnum) {
    TriggerContextTypeEnum["TENANT"] = "tenant";
})(TriggerContextTypeEnum || (TriggerContextTypeEnum = {}));
